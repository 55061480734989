<template>
  <div class="box">
    天星健康
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: center;
  font-size: .6rem;
  font-weight: bold;
  background: rgba(204, 238, 254, 1) url("~@/assets/img/page-bg.png") no-repeat
    center top;
  background-size: 7.5rem 8.64rem;
  padding-top: 2rem;
}
</style>
