<template>
  <div class="page-css">
    <h1>欢迎您注册康复镜账号！</h1>
    <h2>
      为让您更好地使⽤ 智能康复系统
      提供的产品和服务，您应当认真阅读并遵守本《智能康复镜⽤户协议》（“本协议
      ”）
      以及智能康复系统发布的各项协议、规则、条款以及其他内容与⽂件。本协议是北京天星医疗股份有限公司及其关联公司（统称“
      北京天星医疗”）作为智能康复平台（定义⻅下⽂）的运营者和服务提供者，与您（“您
      ”或“⽤户”）就
      您注册、使⽤智能康复平台，购买、使⽤北京天星医疗产品和服务所订⽴的有效合约。
    </h2>
    <h2>
      您点击或勾选智能康复平台相关⻚⾯上的“同意”、“开始训练”、“登录”或者以其他⽅式确认同意/接受本协议
      （如在相关⻚⾯上点击后续操作或已实际享有本协议项下的权益或已实际接受智能康复平台的服务或已实际执⾏本协议项下的部分约定等（“确认”）），即表示您与智能康复系统已达成协议并同意接受本协议全部约定内容的约束。除⾮您已阅读并接受本协议所有条款，否则您⽆权下载、安装或使⽤北京天星医疗提供相关产品和服务。您的下载、安装、使⽤、登录等⾏为即视为您已阅读并同意接受本协议全部约定内容的约束。
      您同意本协议以线上数据电⽂的形式⽴。 在您确认同意本协议之前，请您务必审慎
      、仔细阅读并充分理解本协议的全部内容（特别是免除或者限制责任的相应条款，
      约定争议解决⽅式和司法管辖的条款 ）。 双⽅确认前述条款并⾮属于《 ⺠法典
      》第 四百九⼗七 条规定的
      “不合理地免除或者减轻其责任、加重对⽅责任、限制对⽅主要权利”
      的条款，并同意该条款的合法性及有效性。如果您不同意本协议的任何内容，或者⽆法准确理解本协议任何条款的含义，请不要进⾏确认及后续操作。本协议构成您注册、使⽤智能康复平台之先决条件，您的使⽤⾏为将视为同意接受本协议各项条款的约束。
    </h2>
    <h3>⼀、 定义</h3>
    <p>
      1.1 智能康复平台
      ：指由北京天星医疗股份有限公司及其关联公司运营的可供⽤户浏览、使⽤产品和服务（包括但不限于康复相关产品和服务）的软件平台。
    </p>
    <p>
      1.2
      产品和服务：是指由北京天星医疗提供的智能康复硬件产品（包括但不限于智能镜产品和/或其配件产品）以及由北京天星医疗发布、提供的包括但不限于智能硬件、运动处方、动作库等（形式包括但不限于在线视频、图⽚、软件、技术代码等）。
    </p>
    <p>
      1.3 账号
      ：指您按智能康复平台提供的⽅式设置或取得的供您登录及使⽤智能康复平台服务的编码，该编码可能是您的⼿机号码，也可以是根据智能康复平台规则编辑或设置的其他数字或⽂字。
    </p>
    <h3>⼆、 协议的范围</h3>
    <p>
      2.1 本协议是⽤户与 北京天星医疗
      之间关于⽤户注册、使⽤智能康复平台，购买、使⽤智能康复产品和服务所订⽴的协议，本协议条款的效⼒及于智能康复平台所提供的⼀切产品和服务，⽤户在享受智能康复平台任何单项服务时，亦应当受本协议的约束。
    </p>
    <p>
      2.2
      本协议内容同时包括北京天星医疗隐私政策及所有北京天星医疗已经发布或未来可能发布的各类规则、公告或通知，且您在使⽤北京天星医疗某⼀特定产品或服务时，可能会另有单独的协议、相关服务规则等，前述规则、公告、通知和协议⼀经发布即为本协议不可分割的组成部分，您同样应当遵守。如您不同意本协议的约定，您应⽴即停⽌注册并停⽌使⽤北京天星医疗的相关产品和服务。
    </p>
    <p>
      2.3
      北京天星医疗有权根据需要不时地修订本协议及/或各类规则（包括但不限于制定新的条款和修改既有条款），并以在线公告的⽅式进⾏变更公告，⽆须另⾏单独通知您。修订后的协议和规则⼀经公布，⽴即或在公告明确的特定时间⾃动⽣效。若您在前述公告修订后继续使⽤北京天星医疗的产品或服务，即视为您已阅读、理解并接受北京天星医疗修订后的协议和规则。若您不同意修订后的协议和/或规则，请您⽴即停⽌使⽤北京天星医疗提供的产品和服务。
    </p>
    <p>
      2.4
      除本协议明确规定外，对于利⽤北京天星医疗开发/发布的产品和服务，北京天星医疗有权在本协议的基础上通过单独的产品和服务协议（“
      单项产品协议
      ”）加以规范，您须在使⽤有关产品或服务时另⾏了解与确认。单项产品/服务协议与本协议有冲突的地⽅，以单项产品协议为准。如您使⽤该产品或服务，即视为对相关单项产品协议的接受。
    </p>
    <h3>三、 智能康复产品及服务简介</h3>
    <p>
      3.1
      您确认并认可，您在使⽤智能康复系统所提供的产品和服务时，除该等在线产品和服务之外与⽹络服务有关的设备（如⼿机、个⼈电脑及其他与接⼊互联⽹或移动⽹有关的装置）及所需的费⽤（如为接⼊互联⽹⽽⽀付的电话费及上⽹费、为使⽤移动⽹⽽⽀付的⼿机费）均应由⽤户⾃⾏负担。
    </p>
    <p>3.2 智能康复软件的替换、修改和升级：</p>
    <p class="line">
      3.2.1
      为增进⽤户体验、完善服务内容，北京天星医疗有权根据您对软件的使⽤状态和⾏为开发新的服务。为改进、增强和进⼀步开发新服务，北京天星医疗有权不时提供软件更新（包括软件升级替换、修改、功能强化、版本升级等形式）。您理解并同意：北京天星医疗有权不向您特别通知⽽对智能康复软件进⾏软件更新，或对智能康复软件的部分功能进⾏改变或限制。
    </p>
    <p class="line">
      3.2.2
      智能康复软件的新软件版本发布后，旧版本可能⽆法使⽤。智能康复不保证旧版智能康复软件及相应的服务继续可⽤。为充分、及时使⽤智能康复的服务，请您随时核对并下载最新版本。
    </p>
    <p>
      3.3
      您可以在智能康复软件中开启和使⽤单项或特定服务。某些单项服务可能需要您同时接受就该服务特别制订的协议或者其他约束您与该项服务提供者之间的规则。智能康复系统将以醒⽬的⽅式提供这些协议、规则供您查阅。
      ⼀旦您开始使⽤上述服务，则视为您同时接受有关单项服务的相关协议、规则的约束。
    </p>
    <h3>四、 知识产权声明</h3>
    <p>
      4.1
      北京天星医疗依法享有智能康复平台以及北京天星医疗产品和服务的⼀切合法权属（包括但不限于知识产权及所有权）。除另有特别声明外，北京天星医疗提供智能康复平台、北京天星医疗产品和服务时所依托的著作权、专利权及其他知识产权均归北京天星医疗所有。未经北京天星医疗或相关权利⼈书⾯许可，您不应且不应允许或协助任何⼈以任何形式（包括但不限于通过任何机器⼈、蜘蛛、截屏等程序或设备）进⾏使⽤、出租、出借、分发、展示、复制、
      修改、链接、转载、汇编、发表、出版、抓取、监视、引⽤或创造相关衍⽣作品。
    </p>
    <p>
      4.2
      北京天星医疗在产品和服务中提供的内容、信息（包括但不限于⽹⻚、⽂字、图⽚、⾳频、视频、图表、图标、⾊彩组合、界⾯设计、版⾯框架、有关数据、印刷材料或电⼦⽂档等）的知识产权归北京天星医疗所有。
    </p>
    <p>
      4.3
      通过智能康复平台提供的视频、⾳频、技术软件等产品和信息，除⽤户⾃⼰上传或明确标示由第三⽅提供的之外，全部权利归属于北京天星医疗。
    </p>
    <p>
      4.4 智能康复平台以及北京天星医疗产品和服务中所体现的服务标识均属于
      北京天星医疗在中国或其他国家、地区的商标或者注册商标，⽤户未经合法授权不得⾃⾏使⽤。
    </p>
    <p>
      4.5
      北京天星医疗在涉及智能康复平台、北京天星医疗的产品及相关各项服务中拥有专利权、专利申请权、商标权、著作权
      及其他知识产权。北京天星医疗并未因为本协议或者因为向⽤户提供智能康复平台、北京天星医疗产品及服务⽽授予⽤户任何与北京天星医疗相关的知识产权。本协议未明确授予⽤户的权利均由北京天星医疗保留。
    </p>
    <p>
      4.6
      您理解并同意，您在使⽤北京天星医疗产品或服务时上传、提交、存储或发布的内容（包括但不限于⽂字、图⽚、视频、⾳频、动画等）的知识产权归属您或您已获合法授权，您的上传、提交、存储或发布⾏为不会侵犯他⼈的知识产权或其他合法权益。如果任何第三⽅提出关于知识产权的异议，北京天星医疗有权根据实际情况删除相关的内容，且有权追究您法律责任，给北京天星医疗或任何第三⽅造成损失的，⽤户应负责全额赔偿。
    </p>
    <p>
      4.7
      您同意将您在使⽤北京天星医疗提供的产品或服务时上传、提交或发布的内容授予北京天星医疗永久的、免费的、不可撤销的、⾮独家的使⽤许可以及完整的再许可权利。北京天星医疗有权使⽤、复制、修改、改编、出版、翻译、据以创作衍⽣作品、传播、表演和展示该内容的整体或部分，及/或将该内容⽤于任何北京天星医疗相关的产品、服务、软件或平台上。您进⼀步同意，北京天星医疗有权以北京天星医疗的名义独⽴或委托第三⽅针对侵犯以上权利的⾏为采取任何必要的法律⾏动，包括但不限于⺠事诉讼、⾏政投诉、平台投诉等，并获得依据中华⼈⺠共和国法律可获得的所有救济。
    </p>
    <h3>五、 ⽤户隐私制度</h3>
    <p>
      5.1
      保护⽤户个⼈信息是北京天星医疗的⼀项基本原则。北京天星医疗将按照本协议及北京天星医疗隐私政策的规定收集、使⽤、储存和分享您的个⼈信息。
    </p>
    <p>
      5.2
      您在注册账号或使⽤北京天星医疗产品和服务的过程中，可能需要填写⼀些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，可能⽆法使⽤北京天星医疗的产品或服务，或在使⽤过程中受到限制。
    </p>
    <p>
      5.3
      您可以随时浏览、修改⾃⼰提交的信息，但出于安全性和身份识别（如账号申诉服务）的考虑，您可能⽆法修改注册时提供的部分初始注册信息及其他验证信息。北京天星医疗保留您修改个⼈信息的审核权。
    </p>
    <p>
      5.4
      您了解并同意，北京天星医疗有权应国家有权机关（包括但不限于监管部⻔、司法机关等）的要求，向其提供您在智能康复平台的个⼈信息。如您涉嫌侵犯他⼈合法权益，则北京天星医疗有权在初步判断涉嫌侵权⾏为可能存在的情况下，向权利⼈提供您必要的个⼈信息。
    </p>
    <p>
      5.5
      您同意，北京天星医疗有权使⽤您的注册信息、⽤户名、密码等信息，登陆进⼊您的注册账户，进⾏证据保全，包括但不限于公证、⻅证、协助司法机关进⾏调查取证等。
    </p>
    <p>5.6 北京天星医疗⾮常重视对未成年⼈信息的保护。</p>
    <p class="line">
      5.6.1
      若您是未成年⼈，在使⽤北京天星医疗的服务前，请您在⽗⺟或其他监护⼈的监护、指导下仔细阅读并理解本协议，并在征得您的⽗⺟或其他监护⼈同意的前提下使⽤北京天星医疗的服务和/或向北京天星医疗提供信息。
    </p>
    <p class="line">
      5.6.2
      监护⼈应指导未成年⼈使⽤北京天星医疗的服务和/或向北京天星医疗提供信息的安全问题，防患于未然。未成年⼈使⽤北京天星医疗的产品和/或服务，以及⾏使和履⾏本协议项下的权利和/或义务即视为已获得了监护⼈的认可。
    </p>
    <p class="line">
      5.6.3
      我们提醒未成年⼈在使⽤北京天星医疗产品和/或服务时，要善于学习，避免沉迷于⽹络，影响⽇常的学习⽣活。
    </p>
    <p class="line">
      5.6.4
      如因未成年⼈⽤户违反法律法规、本协议内容，则未成年⼈⽤户及其监护⼈应依照法律规定承担因此⽽导致的⼀切后果。
    </p>
    <h3>六、 ⽤户的账号、密码和安全性</h3>
    <p>
      6.1 注册资格
      您确认，在您完成注册程序或以其他北京天星医疗允许的⽅式实际使⽤北京天星医疗提供的产品和服务时，您应当是具备完全⺠事权利能⼒和完全⺠事⾏为能⼒的⾃然⼈、法⼈或其他组织。若您不具备前述主体资格，则您及您的监护⼈应承担因此⽽导致的⼀切后果，且北京天星医疗有权注销或永久冻结您的账户，并保留向您或您的监护⼈索取赔偿的权利。18周岁以下的未成年⼈及其他⾮完全⺠事⾏为能⼒⼈应该在法定监护⼈的指导下使⽤北京天星医疗的产品和服务。
    </p>
    <p>6.2 注册与账户</p>
    <p class="line">
      6.2.1
      为完整使⽤北京天星医疗提供的产品和服务，您须注册北京天星医疗提供的账户。您注册北京天星医疗账户时须对所提供信息的真实性、合法性、有效性及完整性负责，并及时维护和更新您的个⼈信息，以保证信息的真实、合法和有效。
    </p>
    <p class="line">
      6.2.2
      您在智能康复平台中的注册账号所有权及有关权益均归北京天星医疗所有，您完成注册⼿续后仅享有该账号的使⽤权。您的账号仅限于您本⼈使⽤，未经北京天星医疗事先书⾯同意，禁⽌以任何形式赠与、借⽤、出租、转让、售卖或以其他⽅式许可他⼈使⽤该账号。
      如果北京天星医疗发现或者有合理理由认为使⽤者并⾮账号初始注册⼈，北京天星医疗有权暂时或终⽌向该注册账号提供服务，并有权注销该账号，⽽⽆需向注册该账号的⽤户承担法律责任。
    </p>
    <p>
      6.3
      北京天星医疗在此特别提醒您务必保护好您的账号及密码，切勿将账号及密码透露给他⼈。您须⾃⾏负责对您的账户和密码进⾏保密，且须对在您的账户和密码下发⽣的所有活动承担责任。北京天星医疗不能也不会对因您未能遵守本条款规定⽽发⽣的任何损失负责。若您发现任何⼈未经授权使⽤您的账号及密码，您须⽴即通知北京天星医疗。
      您理解并认可，北京天星医疗根据您的请求采取⾏动需要合理时间，对在采取⾏动前已产⽣的任何不利后果，北京天星医疗不承担任何责任。因其他⼈恶意攻击或您⾃身原因或其他不可抗因素⽽导致账号盗窃、丢失
      ， 均由您本⼈承担责任，北京天星医疗不承担任何责任。
    </p>
    <p>
      6.4 ⽤户注销
      如果您已经深思熟虑，确定放弃您的智能康复平台账户，并符合北京天星医疗规定的所有注销条件，您可在用户端，通过「我的」—「账号与安全」—「注销账号」注销账户，申请注销账户。当您注销账号后，我们将删除或匿名化处理您的个⼈信息。智能康复平台账户⼀旦被注销将不可恢复，请您在操作之前⾃⾏备份智能康复账户相关的所有信息和数据。因您的账户申请注销对您造成的不利影响，北京天星医疗将不承担任何责任。
    </p>
    <h3>七、使⽤规范</h3>
    <p>7.1 ⽤户使⽤北京天星医疗产品与服务时，承诺并遵守以下规定：</p>
    <p class="line">
      （1）
      实施的所有⾏为均遵守国家法律、法规等规范性⽂件及北京天星医疗各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他⼈的合法权益，不违反本协议的相关规则。
    </p>
    <p class="line">
      （2）
      不发布国家禁⽌的或涉嫌侵犯他⼈知识产权或其他合法权益的产品或服务信息，不发布违背社会公共利益或公共道德或北京天星医疗认为不适合在智能康复平台上发布的产品或服务信息，不发布其他涉嫌违法或违反本协议及各类规则的信息。
    </p>
    <p class="line">
      （3）
      除⽤户与北京天星医疗另有约定，⽤户不得对智能康复平台、产品和服务中的任何数据/资料作商业性利⽤
      ，
      包括但不限于在未经北京天星医疗事先书⾯同意的情况下，以复制、传播等任何⽅式使⽤智能康复平台、产品和服务中展示/提供的所有资料或视频。
    </p>
    <p class="line">
      （4）
      不使⽤任何装置、软件或程序⼲预或试图⼲预智能康复平台、产品和服务的正常运作/运⾏或正在智能康复平台上进⾏的任何交易和活动。
    </p>
    <p class="line">
      （5）
      除法律另有强制性规定外，未经北京天星医疗明确的特别书⾯许可，任何单位或个⼈不得以任何⽅式⾮法地全部或部分复制、转载、引⽤、链接、抓取或以其他⽅式使⽤智能康复平台、产品和服务的信息内容，否则，北京天星医疗有权追究其法律责任。
    </p>
    <p>
      7.5
      ⽤户应对其使⽤智能康复平台、产品及服务的⾏为负责，除⾮法律允许或者经北京天星医疗事先书⾯许可，⽤户使⽤智能康复平台、产品及服务不得具有下列⾏为：
    </p>
    <p class="line">
      7.5.1
      使⽤未经授权或许可的任何插件、外挂、系统或第三⽅⼯具对智能康复平台、产品及服务的正常运作/运⾏进⾏⼲扰、破坏、修改或施加其他影响。
    </p>
    <p class="line">
      7.5.2
      利⽤或针对智能康复平台、产品及服务进⾏任何危害计算机⽹络安全的⾏为，包括但不限于：
    </p>
    <p class="line-tow">
      （1） ⾮法侵⼊⽹络、⼲扰⽹络正常功能、窃取⽹络数据等危害⽹络安全的活动；
    </p>
    <p class="line-tow">
      （2）
      提供专⻔⽤于从事侵⼊⽹络、⼲扰⽹络正常功能及防护措施、窃取⽹络数据等危害⽹络安全活动的程序、⼯具；
    </p>
    <p class="line-tow">
      （3）
      明知他⼈从事危害⽹络安全的活动的，为其提供技术⽀持、⼴告推⼴、⽀付结算等帮助；
    </p>
    <p class="line-tow">
      （4） 使⽤未经许可的数据或进⼊未经许可的服务器/账号；
    </p>
    <p class="line-tow">
      （5）
      未经允许进⼊公众计算机⽹络或者他⼈计算机系统并删除、修改、增加存储信息；
    </p>
    <p class="line-tow">
      （6）
      未经许可，企图探查、扫描、测试智能康复系统或⽹络的弱点或其它实施破坏⽹络安全的⾏为；
    </p>
    <p class="line-tow">
      （7）
      企图⼲涉、破坏智能康复系统或⽹站的正常运⾏，故意传播恶意程序或病毒以及其他破坏⼲扰正常⽹络信息服务的⾏为；
    </p>
    <p class="line-tow">（8） 伪造TCP/IP数据包名称或部分名称；</p>
    <p class="line-tow">
      （9）
      对智能康复软件及相关服务进⾏反向⼯程、反向汇编、编译或者以其他⽅式尝试发现智能康复软件及相关服务的源代码；
    </p>
    <p class="line-tow">
      （10） 恶意注册智能康复账号，包括但不限于频繁、批量注册账号；
    </p>
    <p class="line-tow">
      （11） 违反法律法规、本协议、公司的相关规则及侵犯他⼈合法权益的其他⾏为。
    </p>
    <p class="line">
      7.5.3
      如果北京天星医疗有理由认为您的⾏为违反或可能违反上述约定的，北京天星医疗可独⽴进⾏判断并处理，且在任何时候有权在不事先通知的情况下终⽌向您提供服务，并依法追究相关责任。
    </p>
    <p>7.6 ⽤户充分理解并同意：</p>
    <p class="line">
      （1）
      ⽤户如果违反前述承诺及规范，产⽣任何法律后果的，⽤户应以⾃⼰的名义独⽴承担所有的法律责任，
      并确保北京天星医疗免于因此产⽣的任何损失或增加费⽤。
    </p>
    <p class="line">
      （2）
      ⽤户如果违反法律或本协议规定造成北京天星医疗被第三⼈索赔的，⽤户应全额补偿北京天星医疗的⼀切费⽤（包括但不限于各种赔偿费、诉讼代理费及为此⽀出的其他合理费⽤）。
    </p>
    <p class="line">
      （3）
      当第三⽅认为⽤户发表、上传的内容/产品（包括但不限于视频、图⽚、⽂字、应⽤程序、软件等）侵犯其权利，或国家⾏政、司法机关的⽣效法律⽂书确认⽤户存在违法、侵权⾏为，或北京天星医疗基于⾃身判断，认为⽤户的⾏为违反国家法律法规的规定或涉嫌违反本协议条款的，北京天星医疗有权⾃⾏决定删除相关信息。
    </p>
    <p class="line">
      （4）
      对于⽤户在智能康复平台上涉嫌违法、违约或侵犯他⼈合法权利的内容、产品，
      北京天星医疗有权不经通知予以删除，且不承担任何责任。
    </p>
    <p>
      7.7
      ⽤户应规范、合法地使⽤智能康复平台、产品及服务，若您未遵守本协议的任何约定、⽤户⾏为规范、智能康复平台公布的其他规则、规范及/或您在智能康复平台确认/签署的其他协议、⽂件的，北京天星医疗有权做出独⽴判断并⽴即采取以下措施：
    </p>
    <p class="line">（1） 停⽌传输、直接删除违反法律法规或本协议的内容；</p>
    <p class="line">
      （2） 中⽌或限制您使⽤
      智能康复系统及其产品和服务的权利，包括但不限于限制您使⽤智能康复系统部分或全部功能及产品、服务等；
    </p>
    <p class="line">（3） 终⽌您使⽤智能康复平台及其产品和服务的权利；</p>
    <p class="line">
      （4） 限制/取消您的权限、版块、暂停、封禁或关闭您的账号；
    </p>
    <p class="line">
      （5） 回收您的账号，同时有权禁⽌您申请或通过其他⽅式取得新的账号；
    </p>
    <p class="line">（6） 通知合作机构，暂停对您提供有关服务；</p>
    <p class="line">（7） 向国家有关机关报告；</p>
    <p class="line">
      （8）
      将对您上述违约⾏为的处理措施信息以及其他经国家⾏政或司法机关⽣效法律⽂书确认的违法信息在智能康复平台上予以公示；
    </p>
    <p class="line">（9）依法追究您的法律责任。</p>
    <p>
      7.8
      您须对⾃⼰在⽹上的⾔论和⾏为承担法律责任，如您的⾏为导致任何法律后果的发⽣，您应以⾃⼰的名义独⽴承担相应的法律责任
      ，如给北京天星医疗造成任何损失的，则您应⽴即⾜额赔偿北京天星医疗的全部直接与间接损失。
    </p>
    <h3>八、 免责声明</h3>
    <p>
      8.1
      ⽤户理解并同意，在使⽤智能康复平台、产品和服务的过程中，可能会遇到不可抗⼒等⻛险因素，使服务发⽣中断、延迟。不可抗⼒是指不能预⻅、不能克服并不能避免且对⼀⽅或双⽅造成重⼤影响的客观事件，包括但不限于⾃然灾害（如洪⽔、台⻛、地震等）、社会事件（如战争、暴动、政府⾏为）等，出现上述情况时，北京天星医疗会努⼒对产品和服务进⾏修复，但由此给您造成的损失，北京天星医疗在法律允许的范围内免责。
    </p>
    <p>
      8.2
      在法律允许的范围内，北京天星医疗对以下情形导致的服务终端或受阻不承担责任：
    </p>
    <p class="line">
      （1） 受到计算机病毒、⽊⻢或其他恶意程序、⿊客攻击的破坏；
    </p>
    <p class="line">
      （2） ⽤户或北京天星医疗的电脑软件、系统、硬件和通信线路出现故障；
    </p>
    <p class="line">（3） ⽤户操作不当；</p>
    <p class="line">（4） ⽤户使⽤⾮北京天星医疗授权的⽅式使⽤本服务；</p>
    <p class="line">
      （5）
      受到设备运⾏环境（⽐如潮湿）或外部因素（⽐如外部电磁⼲扰、内部互联设备的故障等）的影响；
    </p>
    <p class="line">（6） 其他北京天星医疗⽆法控制或合理预⻅的情形。</p>
    <p>
      8.3
      ⽤户理解并同意，即便在使⽤智能康复产品和服务的过程中特别注意避免造成伤害，部分受伤⻛险仍⽆法消除；前述伤害可能包括：
    </p>
    <p class="line">（1） 轻微伤害，如划伤、擦伤和扭伤；</p>
    <p class="line">
      （2） 严重伤害，如⻣折、脱⾅、丧失视⼒、⼼肌梗死
      及其他⼼肺功能损害、脑震荡、中⻛及其他脑⾎管疾病；及
    </p>
    <p class="line">
      （3） 致命性伤害，包括死亡。 此外，还可能出现急性或慢性的⻣骼、肌⾁、肌腱
      /韧带或其他软组织
      、关节的运动损伤及末端病。因此，若您在任何时候感到任何不适或认为存在受伤的⻛险，您应⽴即停⽌运动，向医⽣咨询。
    </p>
    <p>
      <b
        >此外，还可能出现急性或慢性的⻣骼、肌⾁、肌腱 /韧带或其他软组织
        、关节的运动损伤及末端病。因此，若您在任何时候感到任何不适或认为存在受伤的⻛险，您应⽴即停⽌运动，向医⽣咨询。</b
      >
    </p>
    <p>
      8.4 ⽤户陈述并保证，不存在如下任⼀不适合使⽤智能康复产品和服务的情形：
    </p>
    <p class="line">
      （1）
      本⼈存在任何会妨碍本⼈参与和使⽤智能康复产品和服务的身体状况，包括但不限于任何传染病、炎症、溃疡、糖尿病、低⾎糖、⼼脑⾎管疾病、呼吸系统疾病、新陈代谢类疾病、肢体协调功能障碍类疾病、末端病、免疫类疾病、⾼⾎压、⼼脏病、中⻛、癫痫，以及现在（曾经）患有肌⾁损伤、筋膜损伤、肌腱
      /韧带
      或其他软组织损伤、⻣折、⻣质疏松症、肌⾁扭伤和其他不适合运动的疾病及机体损伤；
    </p>
    <p class="line">（2） 医⽣认定本⼈体能状况不适于从事运动和训练；</p>
    <p class="line">
      （3）
      本⼈在使⽤智能康复服务前或过程中饮酒或饮⽤含酒精类饮料，或服⽤兴奋药剂及其他违禁药品，或身体处于疲劳状态；
    </p>
    <p class="line">
      （4） 本⼈已怀孕、处于围产期或年龄为65周岁以上
      ，且医⽣特别要求本⼈不要从事运动和训练；
    </p>
    <p class="line">
      （5）
      本⼈体内安有⼼脏起搏器、除颤器或其他医疗设备，且经医⽣确认不适合使⽤智能康复设备从事运动和训练。
    </p>
    <p>
      8.5
      ⽤户应了解运动具有潜在的危险性，并愿意⾃⾏承担因使⽤智能康复服务或设备产⽣的任何⻛险。
    </p>
    <p>
      8.6
      ⽤户同意，若⽤户明知其本⼈存在本条款第8.3、8.4条约定情形仍继续使⽤智能康复产品和服务的，⽤户应⾃⾏承担所有⻛险及责任，并在法律允许的范围内，解除、赔偿并保障北京天星医疗、其⼦公司和关联实体及其各⾃董事、管理⼈员、股东、员⼯、代表和代理⼈、及其各⾃继任⼈、受让⼈和所有其他⼈⼠免遭因⽤户参与和使⽤智能康复产品和服务所引起的所有责任、索赔、诉讼、起诉、程序、费⽤、开⽀和损害。
    </p>
    <p>
      8.7
      ⽤户理解并同意，在使⽤智能康复产品和服务的过程中，可能会遇到⽹络信息或其他⽤户带来的⻛险和损害，北京天星医疗不对任何信息或产品的真实性、适⽤性、合法性承担责任，也不对因侵权⾏为给您造成的损害负责。这些⻛险包括但不限于：
    </p>
    <p class="line">
      （1） 来⾃他⼈匿名或冒名的含有威胁、诽谤、令⼈反感或⾮法内容的信息；
    </p>
    <p class="line">
      （2）
      因使⽤本协议项下产品或服务，遭受他⼈误导、欺骗或其他导致或可能导致的任何⼼理、⽣理以及经济上的损害。
    </p>
    <p>
      8.8
      北京天星医疗依据本协议享有处理不法、违规内容、产品或服务的权利，但该权利不构成北京天星医疗的义务和承诺，北京天星医疗不保证及时发现并做出相应处理。
    </p>
    <p>
      8.9
      对于单项产品运营者通过智能康复平台向您提供的单项产品或服务，由单项产品运营者独⽴承担与该项产品或服务有关的全部责任和义务，北京天星医疗对此不承担任何责任和义务。
    </p>
    <p>
      8.10
      为向⽤户提供更好的产品和体验，经北京天星医疗和⽤户同意，北京天星医疗会向部分⽤户销售、提供北京天星医疗尚未公开发售/提供的产品或服务（“
      体验产品/服务
      ”），⽤户可向北京天星医疗反馈使⽤体验、改进建议，北京天星医疗也会向该等⽤户提供相应回馈（具体以北京天星医疗与该等⽤户另⾏签订的产品购买协议及相关其他协议为准）。体验产品/服务可能尚未依法通过国家规定的相关认证，您同意不因此⽽向北京天星医疗提出任何主张或赔偿。
    </p>
    <p>
      8.11
      为更好地为您提供本服务，北京天星医疗将不时进⾏智能康复平台的维护和升级。您同意，届时北京天星医疗仅须提前在智能康复平台发布公告或信息即可，系统维护及升级过程中产⽣的业务中断或不稳定状态，不视为北京天星医疗违约。
    </p>
    <p>
      8.12
      就您购买或使⽤的产品/服务，您应仔细阅读并充分理解相应产品/服务的产品说明书、免责声明及/或其
      他免责条款（统称“免责声明”），北京天星医疗按其在各免责声明中声明的免责情形予以免责。
    </p>
    <h3>九、 产品/服务的变更、中⽌或结束</h3>
    <p>
      9.1
      北京天星医疗有权根据业务发展需要中⽌、变更、终⽌智能康复平台或其部分功能，或调整本协议项下业务有关的规则，并有权决定是否（继续）提供某项智能康复平台中的某种产品或服务；对于前述情形，
      北京天星医疗并不负有向⽤户通知的义务。
    </p>
    <p>
      9.2
      您应规范、合法地使⽤智能康复平台、智能康复产品及服务，如您发布违法、违背社会公德或违背本协议约定的信息，或有违反相关法律法规或本协议的⾏为，北京天星医疗有权采取以下措施：
    </p>
    <p class="line">（1） 直接删除违反法律法规或本协议的内容；</p>
    <p class="line">
      （2）
      中⽌或限制您使⽤智能康复平台、产品和服务的权利，包括但不限于限制您使⽤智能康复平台部分或全部功能及产品、服务等；
    </p>
    <p class="line">（3） 终⽌您使⽤智能康复平台、产品及服务的权利；</p>
    <p class="line">
      （4） 回收您的智能康复账号，同时有权禁⽌您申请或通过其他⽅式取得新的账号；
    </p>
    <p class="line">（5） 依法追究您的法律责任，索取相应损害赔偿⾦。</p>
    <p>9.3 如果您有下列情形发⽣，北京天星医疗有权终⽌您使⽤智能康复平台：</p>
    <p class="line">
      （1）
      您违反相关法律法规，具有⾮法⽬的或利⽤本协议项下的安排实施侵害他⼈合法权益的⾏为；
    </p>
    <p class="line">
      （2）
      您提供虚假注册信息，或存在通过虚构事实、伪造证据、发布虚假信息、与第三⽅串通等⼿段，侵害北京天星医疗及/或其他第三⽅权益的情形的；
    </p>
    <p class="line">
      （3）
      您严重违反本协议及智能康复平台公布的其他规则，北京天星医疗有权依据本协议收回、注销⽤户账号，此情形下，本协议于账号收回、注销之⽇终⽌；
    </p>
    <p class="line">
      （4）
      北京天星医疗有权根据⾃身商业安排经过合理的提前通知终⽌智能康复平台的所有服务，本协议于智能康复平台全部服务依法定程序及⽅式终⽌之⽇终⽌。
    </p>
    <p>
      9.4
      本协议的终⽌不影响本协议终⽌前各⽅的相关权利和义务，包括但不限于守约⽅依据本协议向违约⽅追究相应的违约责任。
    </p>
    <p>
      9.5
      本协议终⽌后，对于您在本协议存续期间产⽣的交易订单，您可与交易相对⽅协商是否继续履⾏，并向智能康复平台提交您与交易相对⽅就此达成的双⽅签字和/或盖章的书⾯协议。否则，北京天星医疗可通知交易相对⽅并根据交易相对⽅的意愿决定是否关闭该等交易订单；如交易相对⽅要求继续履⾏的，则您应当就该等交易订单继续履⾏本协议及交易订单的约定，并承担因此产⽣的任何损失或增加的任何费⽤。
    </p>
    <p>
      9.6
      您理解并同意，在您注销前您应认真阅读、认可隐私政策及智能康复平台上展示上的账户注销规则，并已了解、同意相关⽤户注销流程及注销后的权利义务安排；北京天星医疗将按照隐私政策及账户注销规则的规定处理您的个⼈信息。
    </p>
    <p>
      9.7
      除法律另有规定外，⽤户有责任⾃⾏备份存储在智能康复的数据，如⽤户的服务被终⽌，北京天星医疗有权从服务器上永久地删除您在智能康复平台的全部相关数据，并不负有向您返还或以任何形式提供前述数据的义务
      。
    </p>
    <h3>⼗、 适⽤法律及争议解决⽅式</h3>
    <p>
      10.1
      本协议的成⽴、⽣效、履⾏、解释及纠纷解决，适⽤中华⼈⺠共和国⼤陆地区法律（不包括冲突法）。
    </p>
    <p>
      10.2
      若您与智能康复平台运营者发⽣任何纠纷或争议，⾸先应友好协商解决；协商不成的，您同意将纠纷或争议提交⾄北京天星医疗股份有限公司住所地有管辖权的法院通过诉讼解决。
    </p>
    <h3>⼗一、 违约责任</h3>
    <p>
      11.1
      针对⽤户违反本协议或其他服务条款的⾏为，北京天星医疗有权独⽴判断并视情况采取预先警示、拒绝发布、⽴即停⽌传输信息、限制账号部分或者全部功能直⾄永久关闭账号等措施。
      北京天星医疗有权公告处理结果，且有权根据实际情况决定是否恢复使⽤。对涉嫌违反法律法规、涉嫌违法犯罪的⾏为将保存有关记录，并依法向有关主管部⻔报告、配合有关主管部⻔调查。
    </p>
    <p>
      11.2
      因⽤户违反本协议或其他服务条款规定，引起第三⽅投诉或诉讼索赔的，⽤户应当⾃⾏承担全部法律责任；如使得北京天星医疗遭受第三⼈主张权利，北京天星医疗可在对第三⼈承担⾦钱给付等义务后就全部损失向⽤户追偿。因⽤户的违法或违约⾏为导致北京天星医疗及其关联公司、控制公司向任何第三⽅赔偿或遭受国家机关处罚的，⽤户还应⾜额赔偿北京天星医疗及其关联公司、控制公司因此遭受的全部损失（包括但不限于直接经济损失、商誉损失及对外⽀付的赔偿⾦、和解款、律师费、诉讼费等间接经济损失）
      。
    </p>
    <p>
      11.3
      北京天星医疗尊重并保护⽤户及他⼈的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使⽤智能康复平台、产品及服务时上传的⽂字、图⽚、视频、⾳频、链接等不侵犯任何第三⽅的知识产权、名誉权、
      姓名权、隐私权等权利及合法权益。否则，北京天星医疗有权在收到权利⽅或者相关⽅通知的情况下移除该涉嫌侵权内容。针对第三⽅提出的全部权利主张，您应⾃⾏处理并承担可能因此产⽣的全部法律责任；如因您的侵权⾏为导致北京天星医疗及其关联⽅、控制公司、继承公司遭受任何损失（包括但不限于经济、商誉等损失）的，
      您还应⾜额赔偿
      北京天星医疗及其关联⽅、控制公司、继承公司遭受的全部损失（包括但不限于直接经济损失、
      商誉损失及对外⽀付的赔偿⾦、和解款、律师费、诉讼费等间接经济损失）。
    </p>
    <h3>⼗二、 其他</h3>
    <p>12.1 本协议⾃您按本协议⽂所列明的⽅式确认接受本协议之⽇起⽣效。</p>
    <p>
      12.2
      如本协议中的任何条款因任何原因部分⽆效或不具有执⾏⼒，不影响本协议中其余条款对双⽅的约束⼒。
    </p>
    <p>12.3 本协议条款的标题仅为⽅便阅读⽽设，不作为解释本协议条款的依据。</p>
  </div>
</template>
<script>
export default {
  name: "AgreeMent",
};
</script>
<style lang="scss" scoped>
.page-css {
  width: 100%;
  height: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  background: #fff;
  color: #333;
}

h1 {
  font-size: .46rem;
  font-weight: bold;
}

h2 {
  padding: 6px 0;
  font-size: .46rem;
  font-weight: 400;
}

h3 {
  padding: 6px 0;
  font-size: .46rem;
}

p {
  text-indent: 1.5em;
  font-size: .44rem;
  line-height: 1.6;
}

p.line {
  padding-left: 20px;
}

.line-tow {
  padding-left: 40px;
}
</style>
