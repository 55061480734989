<template>
  <div class="box">
    <div class="no-css">
      <img src="@/assets/img/browser-404.png" />
      <p>页面不存在</p>
    </div>
    <div class="btn-css">
      <van-button type="primary" replace to="/">去首页</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  // justify-content: center;
  flex-direction: column;
  font-size: 0.6rem;
  font-weight: bold;
  background: rgba(204, 238, 254, 1) url("~@/assets/img/page-bg.png") no-repeat
    center top;
  background-size: 7.5rem 8.64rem;
  padding-top: 1rem;
  .no-css {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 4rem;
    }
    p {
      padding-top: .3rem;
      font-size: 0.3rem;
      color: #999;
    }
  }
  .btn-css {
    padding-top: .3rem;
    display: flex;
    justify-content: center;
  }
}
</style>
