import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/Home.vue'
import ShareView from '@/views/share/index.vue'
import NoPage from '@/views/nopage/index.vue'
import pageAgreement from '@/views/agreement/index.vue'
import canvas from '@/views/canvas/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '天星健康'
    }
  },
  {
    path: '/share',
    name: 'share',
    component: ShareView,
    meta: {
      title: '检测报告'
    }
  },
  {
    path: '/useragreement.html',
    name: 'Agreement',
    component: pageAgreement,
    meta: {
      title: '健康镜用户协议'
    }
  },
  {
    path: '/canvas',
    name: 'Canvas',
    component: canvas,
    meta: {
      title: '健康镜用户协议'
    }
  },
  {
    path: '/useragreement',
    name: 'Agreement',
    component: pageAgreement,
    meta: {
      title: '健康镜用户协议'
    }
  },
  {
    path: '*',
    name: 'NoPage',
    component: NoPage,
    meta: {
      title: '页面不存在'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
