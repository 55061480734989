import request from '@/api/axios/index.js'
// 收集埋点
export function collectPointApi(obj) {
  return request({
    url: '/protal/zxwlBuryPoint/collectPoint',
    method: 'post',
    data: obj
  })
}
export function getGatherApi(query) {
  return request({
    url: '/system/v1/assess/gather',
    method: 'get',
    params: query
  })
}
export function getAnalysisApi(query) {
  return request({
    url: '/system/v1/assess/risk-analysis',
    method: 'get',
    params: query
  })
}
