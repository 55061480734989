<template>
  <div v-if="showPage">
    <div class="box">
      <div class="banner-box">
        <div class="banner-text">
          <p>天星体态评估</p>
          <p>检测报告</p>
        </div>
        <div class="banner-img">
          <img src="@/assets/img/banner-img-3.png" alt="" />
        </div>
      </div>
      <div class="top-box">
        <h3 class="top-title">风险评估</h3>
        <div class="top-list">
          <div class="top-item">
            <div class="top-num">
              {{ topData.red || topData.red == 0 ? topData.red : "-" }}
            </div>
            <div class="top-note">高风险</div>
          </div>
          <div class="top-item">
            <div class="top-num">
              {{ topData.yellow || topData.yellow == 0 ? topData.yellow : "-" }}
            </div>
            <div class="top-note">中风险</div>
          </div>
          <div class="top-item">
            <div class="top-num">
              {{ topData.green || topData.green == 0 ? topData.green : "-" }}
            </div>
            <div class="top-note">低风险</div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <template v-if="contentData.length > 0">
          <div
            class="content-item"
            v-for="(item, index) of contentData"
            :key="index"
          >
            <div class="item-title">
              <img class="title-img" src="@/assets/img/title-icon-3.png" alt="" />
              <p>{{ item.itemName }}</p>
            </div>
            <div class="item-css">
              <p class="item-text">{{ item.guide }}</p>
            </div>
            <div class="item-css" v-if="item.causalTitle">
              <p class="item-text">
                {{ item.causalTitle }}
              </p>
              <template v-if="item.causalList">
                <p
                  class="item-text"
                  v-for="(causalText, causalTextIndex) of item.causalList"
                  :key="index + '_' + causalTextIndex"
                >
                  {{ causalText }}
                </p>
              </template>
            </div>
            <div class="item-css" v-if="item.hazardTitle">
              <p class="item-text">
                {{ item.hazardTitle }}
              </p>
              <template v-if="item.hazardList">
                <p
                  class="item-text"
                  v-for="(hazardText, hazardTextIndex) of item.hazardList"
                  :key="index + '_' + hazardTextIndex"
                >
                  {{ hazardText }}
                </p>
              </template>
            </div>
          </div>
        </template>
        <div v-else class="">
          <van-empty description="暂无分析" />
        </div>
      </div>
    </div>
  </div>
  <div class="null-box" v-else>
    <van-loading size="24px" vertical>加载中...</van-loading>
  </div>
</template>

<script>
// import axios from 'axios'
import { getGatherApi, getAnalysisApi } from "@/api/apiList/home.js";
import { setStroe } from "@/util/store.js";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      topData: {
        green: null,
        red: null,
        yellow: null,
      },
      contentData: [],
      showPage: false,
      isToken: false,
    };
  },
  created() {
    let opt = this.$getUrlOptions(window.location.href);
    if (opt.authorization) {
      this.isToken = true;
      setStroe({
        name: "token",
        content: opt.authorization,
      });
    } else {
      this.isToken = false;
      setStroe({
        name: "token",
        content: "",
      });
      console.log("没有token");
    }
  },
  async mounted() {
    if (this.isToken) {
      this.init();
    } else {
      this.contentData = [];
      setTimeout(() => {
        this.showPage = true;
      }, 1000);
    }
  },
  methods: {
    async init() {
      await this.getGatherFun();
      await this.getAnalysisFun();
      this.showPage = true;
    },
    getGatherFun() {
      return new Promise((resolve) => {
        getGatherApi()
          .then((res) => {
            this.topData = res.data || {
              green: null,
              red: null,
              yellow: null,
            };
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getAnalysisFun() {
      return new Promise((resolve) => {
        getAnalysisApi()
          .then((res) => {
            let data = res.data || [];
            let newArr = [];
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                const item = data[i];
                let obj = {
                  itemName: item.itemName,
                  guide: item.guide || "",
                };
                if (item.causalFactor) {
                  item.causalFactor = item.causalFactor.replace("\n\t", "");
                  let strArr = item.causalFactor.split("\n");
                  obj.causalTitle = strArr[0];
                  strArr.shift();
                  obj.causalList = strArr;
                }
                if (item.hazard) {
                  item.hazard = item.hazard.replace("\n\t", "");
                  item.hazard = item.hazard.replace("\t\n", "");
                  let strArr = item.hazard.split("\n");
                  obj.hazardTitle = strArr[0];
                  strArr.shift();
                  obj.hazardList = strArr;
                }
                newArr.push(obj);
              }
            }
            this.contentData = newArr;
            resolve();
          })
          .catch(() => {
            this.contentData = [];
            resolve();
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  background: rgba(204, 238, 254, 1) url("~@/assets/img/page-bg.png") no-repeat
    center top;
  background-size: 7.5rem 8.64rem;
  padding: 0.32rem;
  padding-top: 1.2rem;
  .banner-box {
    position: relative;
    .banner-text {
      padding-left: 0.16rem;
      p {
        text-shadow: 1px 0.04rem 1px rgba(198, 245, 191, 1);
        font-size: 0.48rem;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 0.68rem;
        color: rgba(50, 112, 243, 1);
      }
    }
    .banner-img {
      position: absolute;
      top: -1.2rem;
      right: 0.54rem;
      img {
        display: block;
        width: 2.1rem;
      }
    }
  }
  .top-box {
    width: 100%;
    opacity: 1;
    margin-top: 0.34rem;
    border-radius: 0.08rem;
    background: rgba(255, 255, 255, 0.4) url("~@/assets/img/RISKASSESSMENT.png")
      no-repeat center -0.2rem;
    background-size: 6.42rem 1.06rem;
    border: 2px solid rgba(255, 255, 255, 1);
    box-shadow: inset 0px 2px 0.6rem rgba(220, 246, 253, 1);
    box-sizing: border-box;
    padding: 0.44rem 0.7rem;
    padding-top: 0.1rem;
    text-align: center;
    .top-title {
      position: relative;
      display: inline-block;
      /** 文本1 */
      font-size: 0.36rem;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 0.46rem;
      color: #121b60;
      text-align: center;
      &::before {
        position: absolute;
        top: 50%;
        right: -0.66rem;
        content: "";
        width: 0.3rem;
        height: 0.3rem;
        background: url("~@/assets/img/star.png") no-repeat center;
        background-size: 100%;
        transform: translateY(-50%);
      }
      &::after {
        position: absolute;
        top: 50%;
        left: -0.66rem;
        content: "";
        width: 0.3rem;
        height: 0.3rem;
        background: url("~@/assets/img/star-2.png") no-repeat center;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .top-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.32rem;
      .top-item {
        &:nth-of-type(1) {
          color: #f53f3f;
        }
        &:nth-of-type(2) {
          color: #ffc300;
        }
        &:nth-of-type(3) {
          color: #43cf7c;
        }
        .top-num {
          opacity: 1;
          font-size: 0.48rem;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 0.7rem;
          position: relative;
          &::after {
            position: absolute;
            bottom: 0rem;
            left: 50%;
            transform: translateX(-50%);
            width: 0.6rem;
            height: 2px;
            opacity: 1;
            background: linear-gradient(
              90deg,
              rgba(50, 112, 243, 0) 0%,
              rgba(98, 168, 252, 0.5) 51.85%,
              rgba(50, 112, 243, 0) 100%
            );
            content: "";
          }
        }
        .top-note {
          padding-top: 0.1rem;
          font-size: 0.32rem;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 0.47rem;
        }
      }
    }
  }
  .content-box {
    margin-top: 0.32rem;
    border-radius: 0.08rem;
    background: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 1);
    box-shadow: inset 0px 2px 0.3rem rgba(220, 246, 253, 1);
    padding: 0.32rem;
    .content-item {
      padding-top: .3rem;
      &:first-of-type {
        padding-top: 0;
      }
      .item-title {
        display: flex;
        background: url(~@/assets/img/title-bg.png) no-repeat left bottom;
        background-size: 1.98rem 0.12rem;
        font-size: 0.32rem;
        font-weight: 500;
        line-height: 0.4rem;
        color: #060e69;
        .title-img {
          width: 0.24rem;
          height: 0.3rem;
          transform: translateY(0.06rem);
          margin-right: 0.14rem;
        }
      }
      .item-css {
        padding-top: 0.3rem;
        .item-text {
          font-size: 0.28rem;
          font-weight: 400;
          line-height: 0.4rem;
          color: #060e69;
        }
      }
    }
  }
}
.van-empty ::v-deep {
  .van-empty__description {
    font-size: 0.32rem;
    text-align: center;
    color: #060e69;
  }
}
.null-box {
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
}
</style>
