<template>
  <div class="page-css">
    <div class="canvas-1" ref="canvas1">
      <p>我是TEST1</p>
    </div>
    <div class="canvas-2" ref="canvas2">
      <p>我是我是TEST122222</p>
    </div>
    <button class="btn" @click="btnclick">生成</button>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
export default {
  name: "canVas",
  methods: {
    async btnclick() {
      try {
        const canvas1 = await html2canvas(this.$refs.canvas1); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        console.log(canvas1.toDataURL());
        const canvas2 = await html2canvas(this.$refs.canvas2); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        console.log(canvas2.toDataURL());
        const pdf = new jsPDF({
          orientation: "p", // 纵向
          unit: "mm", // 单位使用毫米
          format: "a4", // 格式为A4纸
        });
        //  A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
        let a4w = 190;
        let a4h = 277;
        let ctxs = [canvas1, canvas2];
        for (let i = 0; i < ctxs.length; i++) {
          let renderedHeight = 0
          let canvas = ctxs[i];
          let page = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          let imgHeight = Math.floor((a4h * canvas.width) / a4w); // 按A4显示比例换算一页图像的像素高度
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight); // 可能内容不足一页
          if (i > 0) {
            pdf.addPage();
          }
          page
            .getContext("2d")
            .putImageData(
              ctx.getImageData(
                0,
                renderedHeight,
                canvas.width,
                Math.min(imgHeight, canvas.height - renderedHeight)
              ),
              0,
              0
            );
          pdf.addImage(
            page.toDataURL("image/png"),
            "png",
            10,
            10,
            a4w,
            Math.min(a4h, (a4w * page.height) / page.width)
          ); // 添加图像到页面，保留10mm边距
        }
        pdf.save("22223.pdf");
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-css {
  .canvas-1 {
    padding: 2rem;
    background: red;
    text-align: center;
    color: #fff;
  }
  .canvas-2 {
    padding: 3rem;
    background: yellowgreen;
    text-align: center;
    color: red;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 0.8rem;
    margin: 0.2rem auto;
    border-radius: 0.1rem;
    color: skyblue;
    color: yellowgreen;
    font-weight: bold;
  }
}
</style>
